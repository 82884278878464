@media only screen and (min-width: 390px) {
  .card-retur {
    display: flex;
  }
}

@media only screen and (max-width: 390px) {
  .btn-retur {
    margin-top: 15px;
  }
}
