.filter-popover{
    position: relative;
    width: 100%;
}

.filter-popover-content{
    position: absolute;
    top: 40px;
    min-width: 320px;
    max-width: 100%;
    min-height: 100px;
    left: 0;
    z-index: 10000000000;
    background-color: #fff;
    display: none;
}
.filter-popover .show{
    display: block;
}