/*!
 * CSS untuk custom 
 */
 body{
    overflow: hidden;
}
.bg-gradient-darkf {
    background-color: #0e0c28;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #0e0c28), to(#373840));
    background-image: linear-gradient(180deg, #0e0c28 10%, #373840 100%);
    background-size: cover;
}

.sidebar .sidebar-brand{
    height: 3rem;
}

body {
    background-color: #efeff6;
}

.top-brand{
    margin-bottom: 15px;
}

.top-brand a:hover,.top-brand a:visited,.top-brand a:active{
    text-decoration: none;
}

.top-brand-img{
    width: 80px;
    height: 80px;
}
.top-brand-text{
    color : #3d405c;
    font-weight: 800;
    font-size: 1.5rem;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom: 5px;
}

.btn-primary-f{
    background-color: #52567a;
    color: #ffffff;
}

.btn-primary-f:hover{
    background-color: #3d405c;
    color: #ffffff;
}


.alert-bottom-right{
    position: fixed;
    display: none;
    bottom: 10px;
    right: 20px;
    z-index: 10000000000;
}

.alert-bottom-center{
    position: fixed;
    display: none;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 10000000000;
}

.alert-bottom-left{
    position: fixed;
    display: none;
    bottom: 10px;
    left: 20px;
    z-index: 10000000000;
}

.alert-top-right{
    position: fixed;
    display: none;
    top: 10px;
    right: 20px;
    z-index: 10000000000;
}

.alert-top-center{
    position: fixed;
    display: none;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 10000000000;
}

.alert-top-left{
    position: fixed;
    display: none;
    top: 10px;
    left: 20px;
    z-index: 10000000000;
}

.alert-show{
    display: block;
}

.table tbody td {
    font-size: 0.85rem;
}

/* override bootstap */
.topbar {
    height: 3rem;
}
.topbar .nav-item .nav-link{
    height: 3rem;
}
/* override bootstap */

.detail-area-fixed{
    height: calc(100vh - 270px);
    overflow: hidden;
    overflow-y: scroll;
    font-size: 0.92rem;
}

/* .table-fixied{
    height: calc(100vh - 220px);
    overflow: hidden;
    overflow-y: auto;
} */
/* .react-bootstrap-table table{ */
    /* table-layout: auto; */
/* } */

.react-bootstrap-table-pagination{
    padding-left: 15px;
    padding-right: 15px;
}

.react-bootstrap-table .table-bordered {
    border-top: none;
  }
  
  .react-bootstrap-table th {
    position: sticky;
    top: 0;
    background-color: #f3f3f3;
    border-top: none;
  }
  
  .react-bootstrap-table th::before {
    position: absolute;
    content: '';
    top: 0;
    left: -2px;
    width: calc( 100% + 2px);
    height: 1px;;
    background-color: #dee2e6;
  }
  
  .react-bootstrap-table th::after {
    position: absolute;
    content: '';
    bottom: -2px;
    left: -2px;
    width: calc( 100% + 2px);
    height: 1px;;
    background-color: #dee2e6;
  }
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    
    background-color: #f3f3f3;
  }
  
  /* Track */
  .custom-scrollbar::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey;  */
    border-radius: 4px;
  }
   
  /* Handle */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgb(214, 214, 214); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #d8d8d8; 
  }

.animate-col-size-change .col-md-7{
    flex-grow: 1;
    transition: all 200ms ease;
}

.animate-col-size-change .col-md-12{
    flex-shrink: 1;
  transition: all 300ms ease;
}

.detail-area{
    display: none;
}

.show-detail-area{
    display: block;
}

.table thead th{
    white-space: nowrap;
    text-overflow: ellipsis;
}

.hidden-alert{
    display: none;
}

.borderless, .borderless td, .borderless th{
    border-top: none;
}

.my-table .table{
    margin-bottom: 70px;
}
.my-table tbody {
    border-bottom: 1px solid #e3e6f0;
}

.my-table .react-bootstrap-table-pagination{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    margin: 0;
}

.vertical-table td {
    padding: 2px 0px;
}

.table-horizontal td{
    padding: 4px;
}

/* .content-wrapper{
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
} */

.content-scroller{
    height: calc(100vh - 40px);
    overflow: hidden;
    overflow-y: scroll;
}

.inner-contain{
    min-height: calc(100vh - 100px)
}

.text-two-line{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2rem;
    height: 2.4rem;
}

.text-three-line{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2rem;
    height: 3.6rem;
}

.custom-card .card-title{
    margin-bottom: 0;
}


.top-progressbar{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    z-index: 10000000;
    display:none
}

.show-top-progressbar{
    display: block;
}

.top-progressbar .progress{
    height: 4px;
}

.top-progressbar .progress-bar{
    background-color: #ff7237;
}

.form-group-no-margin .form-group {
    margin-bottom: 0;
}

.cursor-pointer:hover{
    cursor: pointer;
}

.stepper-heading{
    margin-bottom: 30px;
}

.step-indicator{
    /* border:1px solid #ccc; */
    width: 100%;
    position: relative;
    text-align: center;
}

.step-indicator-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ddd;
    vertical-align: center;
    text-align: center;
    padding-top: 12px;
    position: relative;
    margin: 0 auto;
    z-index: 10;
    user-select: none;
    cursor: pointer;
}

.step-indicator-icon i {
    font-size: 24px;
}
.step-indicator-line{
    height: 3px;
    width: 100%;
    background-color: #999;
    position: absolute;
    left: -50%;
    bottom: 50%;
}

.step-label{
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    user-select: none;
}

.stepper-content {
    margin-top: 70px;
    min-height: 500px;
}

.step-indicator-active .step-indicator-icon {
    background-color: #3d405c;
}
.step-indicator-active .step-indicator-icon i {
    color: #ffffff;
}
.step-indicator-active .step-label {
    color: #3d405c;
    font-weight: bold;
}
.step-indicator-active .step-indicator-line {
    background-color: #3d405c;
}

.stepper-navigation{
    margin-top: 20px;
    margin-bottom: 20px;
}

.ctb th {
    border-top: none !important;
}

.no-mb .form-group {
    margin-bottom: 0;
}

@media screen and (max-width:576px) {
    .col-sm-6{
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.table-responsive .table {
    table-layout: auto;
}
@media screen and (max-width:768px) {
    .react-bootstrap-table-pagination{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .react-bootstrap-table-page-btns-ul .page-link{
        font-size: 0.65rem;
        padding: 0.3rem 0.5rem;
    }
    .react-bootstrap-table-pagination .btn {
        font-size: 0.65rem;
        padding: 0.3rem 0.3rem;
    }
    .sidebar.toggled{
        height: 100vh;
        overflow-y: auto;
    }
}