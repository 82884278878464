.collapsible .content-parent.show {
    height: auto;
 
}

.collapsible :hover {
    background-color: whitesmoke;/* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.collapsible .content-parent {
    height: 0px;
    overflow: hidden;
}

.content {
    overflow: hidden;
}
