.ftree-wrapper {
  width: 100%;
  overflow-x: auto;
  padding: 0px 10px;
  min-height: calc(100vh);
}

.ftree,
.ftree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ftree {
  padding-right: 20px;
}
.ftree ul {
  /* margin-top: -0.5rem; */
  padding-top: 0.3rem;
  margin-left: 0.5em;
  position: relative;
}
.ftree ul ul {
  margin-left: 0.5em;
}
.ftree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px dotted;
}
.ftree li {
  margin: 0;
  margin-bottom: 5px;
  padding: 0 1.8em;
  font-size: 14px;
  line-height: 1.7em;
  /* color: #369; */
  font-weight: 600;
  position: relative;
}

.ftree-group-label {
  cursor: default;
  /* background-color: rgb(221, 235, 247); */
  /* background-color: #4d4d5e; */
  background-color: #fafafa;
  border-radius: 5px;
  color: #252533;
  border: 1px solid #e0e0e0;
  padding-left: 5px;
}
.ftree-group-label-empty {
  /* background-color: #cccccc; */
  min-width: 250px;
}
.ftree-group-label:hover {
  background-color: #252533;
  color: #fff;
}
.ftree-group-label-empty:hover {
  background-color: #cccccc;
}
.ftree-label:hover {
}
.ftree li > div {
  transition: all;
  transition-duration: 300ms;
  text-transform: uppercase;
}
.ftree-wrapper > .ftree > li {
  padding-left: 0px;
  padding-right: 0px;
}
.ftree ul li:before {
  content: "";
  display: block;
  width: 20px;
  height: 0;
  border-top: 1px dotted;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0;
}
.ftree ul li:last-child:before {
  background: #fff;
  height: auto;
  top: 1em;
  bottom: 0;
}
.indicator {
  margin-right: 5px;
}
.ftree li a {
  text-decoration: none;
  color: #369;
}
.ftree li button,
.ftree li button:active,
.ftree li button:focus {
  text-decoration: none;
  color: #369;
  border: none;
  background: transparent;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  outline: 0;
}

.extra-dot {
  border-top: 1px dotted #369;
  display: inline-block;
  width: 17px;
  margin-top: 0px;
  margin-left: -5px;
  height: 6px;
}

.bl-space {
  color: rgb(151, 150, 150);
}

.pl-color {
  color: #f5c23d;
  font-weight: 700;
}
.sv-color {
  color: #50b9cb;
  font-weight: 700;
}

.dd-content {
  /* background-color: rgb(89, 109, 129) !important; */
  background-color: #31313a !important;
  color: #fff !important;
  text-transform: none !important;
  border: none !important;
}

.dd-content-in {
  padding-left: 10px;
}

.dd-content-in > div {
  font-size: 12px;
  line-height: 1.2em;
}
